import React, { useEffect, useState } from 'react'
import SEO from '../common/SEO'
import { Link } from 'react-router-dom'

import HeaderTopNews from '../common/header/HeaderTopNews'
import HeaderOne from '../common/header/HeaderOne'
import FooterTwo from '../common/footer/FooterTwo'
import Copyright from '../common/footer/Copyright'
import Slider from 'react-slick'
import classes from './TestPage.module.css'
import { BannerActivation } from '../utils/script'
import Separator from '../elements/separator/Separator'
import SectionTitle from '../elements/sectionTitle/SectionTitle'
import ServiceOne from '../elements/service/ServiceOne'
import AboutFour from '../elements/about/AboutFour'
import GalleryOne from '../elements/gallery/GalleryOne'
import TestimonialThree from '../elements/testimonial/TestimonialThree'
import BlogList from '../components/blog/itemProp/BlogList'
import BlogClassicData from '../data/blog/BlogList.json'
import BlogItem from '../mycomponents/BlogItem'

import timedata from '../data/dashboard/sfhh@4.json'
import TimeNetwork from '../dashboard/TimeNetwork'
import AboutTechnologist from '../mycomponents/AboutTechnologist'
import AboutTechnologist2 from '../elements/about/AboutTechnologist2'
import Competencies from '../mycomponents/Competencies'
import { FiCheck } from 'react-icons/fi'

import SkillsTab from '../mycomponents/SkillsTab'
import BreadcrumbTwo from '../mycomponents/breadcrumb/BreadcrumbTwo'

const Overview = () => {
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  const [width, setWidth] = useState(
    document.documentElement.clientWidth -
      Math.round(document.documentElement.clientWidth * 0.25)
  )

  const timeNetwork = [
    <TimeNetwork
      height={height}
      width={width}
      data={timedata}
      colorOne={'white'}
      colorTwo={'black'}
    />,
  ]

  const BannerData = [
    {
      image: '',
      link: 'technologist-nlp',
      title: 'NLP Portfolio',
      description: '',
      component: [
        <TimeNetwork
          height={height}
          width={width}
          data={timedata}
          colorOne={'blue'}
          colorTwo={'limegreen'}
          radius={6}
          timeStep={300}
        />,
      ],
    },
    {
      image: '',
      link: 'technologist-ai',
      title: 'AI Portfolio',
      description: '',
      component: [
        <TimeNetwork
          height={height}
          width={width}
          data={timedata}
          colorOne={'orange'}
          colorTwo={'red'}
          radius={3}
          timeStep={50}
        />,
      ],
    },
    // {
    //   image: '',
    //   link: '',
    //   title: 'Dashboards',
    //   description: '',
    //   component: [
    //     <TimeNetwork
    //       height={height}
    //       width={width}
    //       data={timedata}
    //       colorOne={'white'}
    //       colorTwo={'purple'}
    //       radius={12}
    //       timeStep={200}
    //     />,
    //   ],
    // },
  ]
  useEffect(() => {}, [])
  return (
    <>
      <SEO title='Technologist' />
      <main className='page-wrapper'>
        <HeaderOne btnStyle='btn-small' HeaderSTyle='header-not-transparent' />
        <Separator />
        <div className='about-area about-style-4'>
          <div className='col-lg-12 ml--100 mr--100 mt_md--40 mt_sm--40'>
            <div className='content'></div>
          </div>
        </div>
        <BreadcrumbTwo
          title='Technologist'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Advance Tab'
        />
        <div className=''>
          <div className='container'>
            <div className='row row--15'>
              {BannerData.map((item) => (
                <div
                  key={item.id}
                  className='col-lg-6 col-md-6 col-sm-12 col-12 mt--30'
                >
                  <BlogItem StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Core Competencies'
                  title=''
                  description='Looking for a data scientist or NLP engineer?'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <SkillsTab />
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'></div>
            </div>
            <div className='row row--15'></div>
          </div>
        </div>
        <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'></div>
            </div>
            <div className='row row--15'></div>
          </div>
        </div> */}

        <Separator />
        <Copyright />
      </main>
    </>
  )
}

export default Overview
