import React from 'react'
import BlogClassicData from '../data/blog/BlogList.json'
import BlogDetailsContent from '../components/blog/BlogDetailsContent'
import SEO from '../common/SEO'
import Layout from '../common/Layout'

const BlogDetails = ({
  match: {
    params: { id },
  },
}) => {
  const blogId = parseInt(id, 10)
  const data = BlogClassicData.filter((blog) => blog.id === blogId)

  return (
    <>
      <SEO title='Hire Me' />
      <Layout>
        <div className='fixed inset-0 overflow-hidden'>
          <div className='h-full overflow-y-auto'>
            <div className='min-h-full'>
              <div className='rn-blog-details-area'>
                <BlogDetailsContent data={data[0]} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default BlogDetails
