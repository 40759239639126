import React, { useState, useRef, useEffect } from 'react'
import { select, drag } from 'd3'

const InteractiveOwl = () => {
  const [parts, setParts] = useState({
    body: { x: 0, y: 0, color: '#8B4513' },
    leftEye: { x: 0, y: 0, color: '#FFD700' },
    rightEye: { x: 0, y: 0, color: '#FFD700' },
    beak: { x: 0, y: 0, color: '#FFA500' },
    leftWing: { x: 0, y: 0, color: '#8B4513' },
    rightWing: { x: 0, y: 0, color: '#8B4513' },
  })

  const [isStuck, setIsStuck] = useState(false)
  const svgRef = useRef()

  const colorSchemes = [
    ['#8B4513', '#FFD700', '#FFA500'], // Brown, Gold, Orange
    ['#4B0082', '#00FF00', '#FF1493'], // Indigo, Lime, Pink
    ['#000080', '#87CEEB', '#FF4500'], // Navy, Sky Blue, Red-Orange
    ['#2F4F4F', '#7FFF00', '#FF69B4'], // Dark Slate, Chartreuse, Hot Pink
  ]

  const [currentScheme, setCurrentScheme] = useState(0)

  useEffect(() => {
    const svg = select(svgRef.current)

    // Apply dragging behavior to each part
    Object.keys(parts).forEach((partId) => {
      const dragBehavior = drag().on('drag', (event) => {
        if (!isStuck) {
          setParts((prev) => ({
            ...prev,
            [partId]: {
              ...prev[partId],
              x: prev[partId].x + event.dx,
              y: prev[partId].y + event.dy,
            },
          }))
        }
      })

      svg.select(`#${partId}`).call(dragBehavior)
    })
  }, [isStuck])

  // Add wiggle animation when stuck
  useEffect(() => {
    if (isStuck) {
      const interval = setInterval(() => {
        setParts((prev) => {
          const newParts = { ...prev }
          Object.keys(newParts).forEach((part) => {
            newParts[part] = {
              ...newParts[part],
              x: newParts[part].x + (Math.random() - 0.5) * 2,
              y: newParts[part].y + (Math.random() - 0.5) * 2,
            }
          })
          return newParts
        })
      }, 150)

      return () => clearInterval(interval)
    }
  }, [isStuck])

  const handlePartClick = (partId) => {
    setCurrentScheme((prev) => (prev + 1) % colorSchemes.length)
    setParts((prev) => ({
      ...prev,
      [partId]: {
        ...prev[partId],
        color:
          colorSchemes[currentScheme][
            partId.includes('Eye') ? 1 : partId === 'beak' ? 2 : 0
          ],
      },
    }))
  }

  const resetPositions = () => {
    setParts((prev) => {
      const reset = { ...prev }
      Object.keys(reset).forEach((part) => {
        reset[part] = { ...reset[part], x: 0, y: 0 }
      })
      return reset
    })
  }

  return (
    <div className='flex flex-col items-center space-y-4'>
      <svg ref={svgRef} viewBox='-150 -150 300 300' className='w-96 h-96'>
        {/* Body */}
        <path
          id='body'
          d='M0,0 a70,90 0 1,0 1,0 z'
          fill={parts.body.color}
          transform={`translate(${parts.body.x},${parts.body.y})`}
          onClick={() => handlePartClick('body')}
          className='cursor-pointer'
        />

        {/* Left Eye */}
        <circle
          id='leftEye'
          cx='-25'
          cy='-20'
          r='20'
          fill={parts.leftEye.color}
          transform={`translate(${parts.leftEye.x},${parts.leftEye.y})`}
          onClick={() => handlePartClick('leftEye')}
          className='cursor-pointer'
        />

        {/* Right Eye */}
        <circle
          id='rightEye'
          cx='25'
          cy='-20'
          r='20'
          fill={parts.rightEye.color}
          transform={`translate(${parts.rightEye.x},${parts.rightEye.y})`}
          onClick={() => handlePartClick('rightEye')}
          className='cursor-pointer'
        />

        {/* Beak */}
        <path
          id='beak'
          d='M-10,10 L10,10 L0,30 Z'
          fill={parts.beak.color}
          transform={`translate(${parts.beak.x},${parts.beak.y})`}
          onClick={() => handlePartClick('beak')}
          className='cursor-pointer'
        />

        {/* Left Wing */}
        <path
          id='leftWing'
          d='M-70,0 Q-90,50 -60,90 L-40,80 Q-60,50 -50,0 Z'
          fill={parts.leftWing.color}
          transform={`translate(${parts.leftWing.x},${parts.leftWing.y})`}
          onClick={() => handlePartClick('leftWing')}
          className='cursor-pointer'
        />

        {/* Right Wing */}
        <path
          id='rightWing'
          d='M70,0 Q90,50 60,90 L40,80 Q60,50 50,0 Z'
          fill={parts.rightWing.color}
          transform={`translate(${parts.rightWing.x},${parts.rightWing.y})`}
          onClick={() => handlePartClick('rightWing')}
          className='cursor-pointer'
        />
      </svg>

      <div className='space-x-4'>
        <button
          onClick={() => setIsStuck(!isStuck)}
          className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
        >
          {isStuck ? 'Unstick' : 'Stick'} Owl Parts
        </button>

        <button
          onClick={resetPositions}
          className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600'
        >
          Reset Positions
        </button>
      </div>
    </div>
  )
}

export default InteractiveOwl
