import React from 'react'
import SEO from '../common/SEO'
import HeaderOne from '../common/header/HeaderOne'
import Copyright from '../common/footer/Copyright'
import Separator from '../elements/separator/Separator'
import SectionTitle from '../elements/sectionTitle/SectionTitle'
import BlogList from '../components/blog/itemProp/BlogList'
import BlogClassicData from '../data/blog/BlogList.json'

var BlogListData = BlogClassicData.slice(0, 3)

const Educator = () => {
  return (
    <>
      <SEO title='Educator' />
      <main className='page-wrapper min-h-full'>
        <HeaderOne btnStyle='btn-small' HeaderSTyle='header-not-transparent' />
        <Separator />
        <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='World Class'
                  title='Educator'
                  text='Looking for a top-notch teacher, tutor, or academic editor?'
                />
              </div>
            </div>
            {/* <div>
              <ProjectAccordion data={BannerData} />
            </div> */}
            <div className='row row--15'>
              {BlogListData.map((item) => (
                <div
                  key={item.id}
                  className='col-lg-4 col-md-6 col-sm-12 col-12 mt--30'
                >
                  <BlogList StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Separator />
        <Separator />
        <Copyright />
      </main>
    </>
  )
}

export default Educator
