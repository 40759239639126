import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabOne = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              {/* <div className="col-lg-5">
                <img
                  className="radius-small"
                  src="./images/tab/tabs-01.jpg"
                  alt="Corporate React Template"
                />
              </div> */}
              <div className="col-lg-12 mt_md--40 mt_sm--40">
                <div className="rn-default-tab">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Data Science</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>NLP</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>WebDev</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>EdTech</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>AWS</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            I have a master’s degree in applied data science
                            from the University of Michigan and am quite
                            competent in a wide variety of machine learning and
                            deep learning techniques, as well as data
                            visualization. My favorite python libraries include
                            scikit-learn and PyTorch and I have great confidence
                            in my ability to create ML/DL pipelines for numerous
                            use cases.
                          </p>
                          <p>
                            My true passion is NLP, but I am always eager to
                            work with any type of structured or unstructured
                            data. Looking for a data scientist like me? Contact
                            me and let’s start a dialogue.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            My true technological passion is natural language
                            processing. Perhaps due to my background as a
                            language teacher and linguistics enthusiast, I’m
                            absolutely fascinated by the power and potential of
                            NLP algorithms. I’m highly adept at all the typical
                            NLP libraries such as NLTK and spaCy, and am quite
                            competent in even the cutting edge of language
                            technology.
                          </p>
                          <p>
                            After countless hours of study and practice, I’m
                            comfortable implementing transformer-based
                            algorithms like BERT, T5, and GPT from scratch with
                            PyTorch. Why bother though when we have access to
                            amazing tools like HuggingFace to fine-tune our
                            models? As such, I am most definitely seeking some
                            sort of employment that would allow me to continue
                            to exercise my passion for NLP.
                          </p>
                          <p>
                            Looking for an NLP engineer? I might be your guy!
                            Don’t hesitate to reach out and start a dialogue.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            React.js is such a fun library to work with. With
                            React, it’s possible to make an broad range of web
                            applications and I’ve become quite skilled at making
                            MERN stacks.
                          </p>
                          <p>
                            Given my background in data science, I’m also a huge
                            fan of the D3 library to make visually stunning
                            dashboards that communicate data insights
                            dynamically. Would you like me to make you a simple
                            static website like this one? Or maybe a more
                            elaborate project like a live dashboard? Don’t
                            hesitate to get in touch.
                          </p>
                          <p>
                            I’ve still got more work to do in terms of learning
                            React Native for mobile development, but I’ve got
                            some fun projects in the works that will hopefully
                            appear in the App Store in the near future.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            My background as an international teacher has given
                            me significant insight into the potential for
                            ed-tech to improve education systems. Namely, I
                            believe that students throughout the world deserve
                            to be educated in their native language, something
                            that is not possible for many students due to the
                            colonial history of many education systems worldwide
                            and a general lack of resources for education. As
                            smartphones become ubiquitous, this education gap
                            might well be addressed with language technology. As
                            tools like transformer-based AI models become more
                            powerful, I believe that there is enormous potential
                            to revolutionize literacy and language education
                            worldwide.
                          </p>

                          <p>
                            I’m chock full of ideas related to language
                            technology for education and would truly enjoy
                            collaborating with other technologists and educators
                            to create amazing tools to improve educational
                            outcomes for the world’s youth. Want to collaborate?
                            Don’t hesitate to get in touch.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            I’m quite competent at using AWS micro-services both
                            for web development and machine learning pipelines.
                            I’m more than capable of using tools such as
                            Sagemaker and API gateway to make machine learning
                            endpoints. I’m also very comfortable using S3,
                            Elastic Beanstalk, CodePipeline, CloudFront and
                            other micro-services to create a variety of
                            projects.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabOne;
