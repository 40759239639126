import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import classes from './Dashboard.module.css'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'

const DirectionalNetwork = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null)
  const containerRef = useRef(null)
  const [nodesUnstick, setNodesUnstick] = useState(true)

  const margin = { top: 10, right: 10, bottom: 10, left: 10 },
    width = props.width - margin.left - margin.right,
    height = props.width > 1000 ? 700 : props.width - margin.top - margin.bottom

  const types = ['Montague', 'Montague Adj.', 'Capulet', 'Capulet Adj.']
  const color = d3.scaleOrdinal(types, d3.schemeCategory10)

  const thickness = (d) => {
    return Number(d)
  }
  useEffect(() => {
    let data = props.data
    const svgEl = d3.select(svgRef.current)
    svgEl.selectAll('*').remove()
    console.log(props.data)
    const links = data.links.map((d) => Object.create(d))
    const nodes = data.nodes.map((d) => Object.create(d))

    const drag = (simulation) => {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart()
        d.fx = d.x
        d.fy = d.y
      }

      function dragged(event, d) {
        d.fx = event.x
        d.fy = event.y
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0)
        d.fx = null
        d.fy = null
      }

      return d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    }

    const linkArc = (d) => {
      const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y)
      return `
          M${d.source.x},${d.source.y}
          A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
        `
    }
    const simulation = d3
      .forceSimulation(nodes)
      .force(
        'link',
        d3.forceLink(links).id((d) => d.id)
      )
      .force('charge', d3.forceManyBody().strength(-400))
      .force('x', d3.forceX())
      .force('y', d3.forceY())

    const svg = svgEl
      .append('svg')
      // .attr("width", width)
      // .attr("height", height)
      .attr('viewBox', [-width / 2, -height / 2, width, height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')

    svg
      .append('defs')
      .selectAll('marker')
      .data(types)
      .join('marker')
      .attr('id', (d) => `arrow-${d}`)
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 15)
      .attr('refY', -0.5)
      .attr('markerWidth', 6)
      .attr('markerHeight', 6)
      .attr('orient', 'auto')
      .append('path')
      .attr('fill', color)
      .attr('d', 'M0,-5L10,0L0,5')

    const link = svg
      .append('g')
      .attr('fill', 'none')

      .selectAll('path')
      .data(links)

      .join('path')
      .attr('stroke', (d) => color(d.type))
      .attr('marker-end', (d) => `url(${`#arrow-${d.type}`})`)

    const node = svg
      .append('g')
      .attr('fill', 'currentColor')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .selectAll('g')
      .data(nodes)
      .join('g')
      .call(drag(simulation))

    link.attr('stroke-width', (d) => d.count / 10)
    node
      .append('circle')
      .attr('stroke', 'white')
      .attr('stroke-width', 1.5)
      .attr('r', 4)

    node
      .append('text')
      .attr('x', 8)
      .attr('y', '0.31em')
      .text((d) => d.id)
      .clone(true)
      .lower()
      .attr('fill', 'none')
      .attr('stroke', 'white')
      .attr('stroke-width', 3)

    simulation.on('tick', () => {
      link.attr('d', linkArc)
      node.attr('transform', (d) => `translate(${d.x},${d.y})`)
    })
  }, [props.data, props.height, props.width])

  return (
    <div
      id='chartContainer'
      className={classes.networkContainer}
      ref={containerRef}
    >
      <div id='DirectionalNetwork'>
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>

      <div className='row ml--10 mr--10 mb--5 mt--10'>
        <p>
          {
            'How do U.S. embassies talk about different countries? The networked word vectors above are derived from U.S. embassy Twitter data. Notice how perceived allies and adversaries are associated with different vocabulary.'
          }
        </p>
      </div>
      <div className='row ml--10 mr--10 mb--5 mt--10'>
        {/* <button
          className='btn-default btn-small mr--10 ml--10'
          onClick={unstickNodes}
        >
          Unstick Nodes
        </button> */}
        <Link className='btn-default btn-small mr--10 ml--10' to={`/`}>
          See Code
        </Link>
      </div>
    </div>
  )
}

export default DirectionalNetwork
